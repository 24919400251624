import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMusic, faCompactDisc } from "@fortawesome/free-solid-svg-icons";

const Nav = ({ libraryStatus, setLibraryStatus }) => {
  return (
    <nav>
      <h1>
        Rádio <FontAwesomeIcon icon={faCompactDisc} /> Clei
      </h1>
      <button onClick={() => setLibraryStatus(!libraryStatus)}>
        Músicas <FontAwesomeIcon icon={faMusic} />
        <FontAwesomeIcon icon="fa-brands fa-galactic-republic" />
      </button>
    </nav>
  );
};

export default Nav;




